import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import CreateModel from "../views/CreateModel.vue";
import Reports from "../views/Reports.vue";
import ListModels from "../views/ListModels.vue";
import ManageModels from "../views/ManageModels.vue";
import CreateVoucher from "../views/CreateVoucher.vue";
import Users from "../views/Users.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "login",
    component: Login,
    meta: {
      layout: "fullscreen",
    },
  },
  {
    path: "/modelos",
    name: "Modelos",
    component: ListModels,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/gerenciar-modelos",
    name: "Gerenciar Modelos",
    component: ManageModels,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/usuarios",
    name: "Gerenciar Usuarios",
    component: Users,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/criar-modelo",
    name: "Criar Modelo",
    component: CreateModel,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/relatorios",
    name: "Relatórios",
    component: Reports,
    meta: {
      layout: "menu",
    },
  },
  {
    path: "/criar-voucher/:model_id",
    name: "Criar Voucher",
    component: CreateVoucher,
    meta: {
      layout: "menu",
    },
  },
  
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const publicPages = ["/"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");

  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
