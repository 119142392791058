<template>
    <div class="reports_container">
        <h1 style="margin-bottom: 20px;">Relatórios</h1>

        <v-row>
            <v-col cols="12" sm="12" md="4" lg="4">
                <v-text-field v-model="search" label="Buscar por modelo ou texto" append-icon="mdi-magnify" outlined hide-details="auto"></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
                <v-select :items="filter_field" v-model="order_field" item-text="name" item-value="id" label="Ordenar por campo" outlined @change="sortVouchers" hide-details="auto"></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
                <v-select :items="filter_order" v-model="sort" item-text="name" item-value="id" label="Ordenação" outlined @change="sortVouchers"></v-select>
            </v-col>




            <v-col cols="12" sm="12" md="4" lg="4">
                <v-select :items="filter_field" v-model="find_by_date_field" item-text="name" item-value="id" label="Campo do filtro" outlined hide-details="auto"></v-select>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
                <v-dialog ref="dialog1" v-model="modal1" :return-value.sync="start_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="start_date" label="Data inicial" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal1 = false">
                            Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog1.save(date1)">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
                <v-dialog ref="dialog2" v-model="modal2" :return-value.sync="end_date" persistent width="290px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field v-model="end_date" label="Data final" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="date2" scrollable clearable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal2 = false">
                            Cancelar
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog2.save(date2)">
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <v-col cols="12" sm="12" md="12" lg="12" class="mb-6">
                <v-btn large color="primary" @click.native="filter" style="float: right;">Buscar</v-btn>
                <v-btn large color="error" @click.native="clearFilter" class="mr-4" style="float: right;">Limpar filtros</v-btn>
            </v-col>
        </v-row>


        <v-data-table :headers="headers" :items="vouchers" :items-per-page="30" class="elevation-1" :footer-props="{
            'items-per-page-text': 'Linhas por página',
            'items-per-page-all-text': 'Tudo',
            'items-per-page-options': [5, 10, 30, 50, 100, -1],
        }">

            <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-4" @click.native="open(item)"> mdi-open-in-new</v-icon>
            </template>

            <template v-slot:[`item._expiration_date`]="{ item }">
                <div>
                    {{ item._expiration_date }}
                    <v-icon v-if="item.expired" small class="mr-4" color="red"> mdi-close-circle</v-icon>
                    <v-icon v-if="item.will_expire" small class="mr-4" color="orange"> mdi-alert-circle</v-icon>
                </div>
            </template>
        </v-data-table>
    </div>
</template>
  
<script>
import Api from '@/lib/Api';

export default {
    name: "ReportsView",

    data() {
        return {
            vouchers: [],
            server_vouchers: [],
            headers: [
                { text: "Modelo", value: "model.title", filterable: true, sortable: true },
                { text: "Texto", value: "text", filterable: true, sortable: true },
                { text: "Data de criação", value: "_created" },
                { text: "Validade", value: "_expiration_date" },
                { text: "Autor", value: "user.name", filterable: true, sortable: true },
                { text: "Ações", value: "actions", sortable: true },
            ],
            filter_field: [
                { id: "created", name: "Data de criação" },
                { id: "expiration_date", name: "Validade" },
            ],
            filter_order: [
                { id: "asc", name: "Crescente" },
                { id: "desc", name: "Decrescente" },
            ],
            order_field: 'created',
            sort: 'desc',
            modal1: false,
            modal2: false,
            start_date: '',
            end_date: '',
            date1: '',
            date2: '',
            search: '',
            find_by_date_field: 'created'

        };
    },

    async mounted() {
        this.getVouchers();
    },

    methods: {
        async getVouchers() {
            const resp = await Api.getVouchers();
            if (resp && resp.vouchers) {
                const now = new Date();
                let vouchers = resp.vouchers;
                vouchers.forEach(v => {
                    v._created = new Date(v.created).toLocaleString();
                    v._expiration_date = new Date(v.expiration_date).toLocaleDateString();

                    const expiration_date = new Date(v.expiration_date);

                    if (expiration_date < now && expiration_date.toLocaleDateString() != now.toLocaleDateString()) {
                        v.expired = true;
                    }

                    if (expiration_date - now > 0 && expiration_date - now <= 3 * 24 * 60 * 60 * 1000) {
                        v.will_expire = true;
                    }

                })
                this.server_vouchers = vouchers;
                this.sortVouchers();
            }
        },

        sortVouchers() {
            if (this.sort === 'desc') {
                this.vouchers = this.server_vouchers.sort((a, b) => new Date(b[this.order_field]) - new Date(a[this.order_field]));
            }
            else {
                this.vouchers = this.server_vouchers.sort((a, b) => new Date(a[this.order_field]) - new Date(b[this.order_field]));
            }
        },

        open(item) {
            window.open(item.image, '_blank').focus();
        },

        filter() {
            this.vouchers = this.server_vouchers.filter(v => v.model.title.toLowerCase().includes(this.search.toLocaleLowerCase()) || v.text.toLowerCase().includes(this.search.toLocaleLowerCase()));

            let start_date;
            let end_date;

            if (this.start_date != '') {
                start_date = new Date(this.start_date);
                start_date = Number(start_date) +  Number(3 * 60 * 60 * 1000 + 1);
            }

            if (this.end_date != '') {
                end_date = new Date(this.end_date);
                end_date = Number(end_date) +  Number(3 * 60 * 60 * 1000 + 1);
                end_date = new Date(end_date)
                end_date.setHours(23, 59, 59, 1);
            }

            if (start_date && !end_date) this.vouchers = this.server_vouchers.filter(v => new Date(v[this.find_by_date_field]) >= start_date);
            if (end_date && !start_date) this.vouchers = this.server_vouchers.filter(v => new Date(v[this.find_by_date_field]) <= end_date);
            if (start_date && end_date) this.vouchers = this.server_vouchers.filter(v => (new Date(v[this.find_by_date_field]) >= start_date) && (new Date(v[this.find_by_date_field]) <= end_date));
        },

        clearFilter() {
            this.vouchers = this.server_vouchers;
            this.search = ''
            this.start_date = ''
            this.end_date = ''
        }
    },
};
</script>
  
<style scoped></style>
  