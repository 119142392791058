<template>
    <div class="vouchers_container">
        <h1>Criar Voucher</h1>

        <v-card class="voucher-card">
            <v-card-text>
                <v-row>

                    <v-col cols="12" lg="4" md="6" sm="12">
                        <v-text-field v-model="text" label="Texto do voucher" outlined hide-details="auto" class="input" @change="changeText"></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="4" md="6" sm="12">
                        <v-dialog ref="dialog" v-model="modal" :return-value.sync="valid" persistent width="290px">
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="valid" label="Validade do voucher" prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                            </template>
                            <v-date-picker v-model="date" scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="modal = false">
                                    Cancelar
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.dialog.save(date)">
                                    OK
                                </v-btn>
                            </v-date-picker>
                        </v-dialog>
                    </v-col>

                    <v-col cols="12" lg="4" md="6" sm="12">
                        <v-slider v-model="font_size" label="Tamanho do texto" max="256" min="8" @change="changeText"></v-slider>
                    </v-col>

                    <v-col cols="12" lg="4" md="6" sm="12">
                        <v-slider v-model="x_axis" label="Posição horizontal" max="100" min="0" @change="changeText"></v-slider>
                    </v-col>

                    <v-col cols="12" lg="4" md="6" sm="12">
                        <v-slider v-model="y_axis" label="Posição vertical" max="100" min="0" @change="changeText"></v-slider>
                    </v-col>
                    <v-col cols="12" lg="4" md="6" sm="12">
                        <label style="margin-right: 10px;">Cor do texto</label>
                        <input v-model="text_color" label="olar" id="color1" type="color" @change="changeText" />
                    </v-col>

                </v-row>

                <img id="voucher-image" :src="model.image">

            </v-card-text>

            <div class="voucher-container">
                <canvas id="myCanvas" style="border:1px solid #d3d3d3;">
                    Your browser does not support the HTML5 canvas tag.
                </canvas>
            </div>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn depressed large color="primary" @click.native="createVoucher">
                    Salvar Voucher
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
  
<script>
//import Api from "@/lib/Api";
//import { emitToastr } from "@/lib/Utils";

import Api from '@/lib/Api';
import { emitToastr } from '@/lib/Utils';
var canvas;
var ctx;

export default {
    name: "CreateVoucherView",

    data() {
        return {
            model: {},
            text: '',
            valid: '',
            date: '',
            text_color: '#000000',
            font_size: 16,
            x_axis: 20,
            y_axis: 20,
            modal: false
        };
    },

    async mounted() {
        if (this.$route.params && this.$route.params.model_id) {
            const model = await Api.getModelById(this.$route.params.model_id);
            if (model && model._id) this.model = model;

            canvas = document.getElementById("myCanvas");
            ctx = canvas.getContext("2d");
            this.createCanvas();
        }
    },

    watch: {
        date() {
            this.valid = this.formatDate(this.date);
            this.changeText();
        },
    },

    methods: {
        createCanvas() {
            var img = document.getElementById("voucher-image");
            img.setAttribute('crossorigin', 'anonymous');
            ctx.canvas.width = img.width;
            ctx.canvas.height = img.height;
            ctx.drawImage(img, 0, 0, img.width, img.height);
        },

        clearCanvas() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        },

        changeText() {
            this.createCanvas();
            var canvas = document.getElementById("myCanvas");
            var ctx = canvas.getContext("2d");

            this.valid = this.formatDate(this.date);
            ctx.font = `${this.font_size}px serif`;
            ctx.fillStyle = this.text_color;
            ctx.fillText(this.text, (this.x_axis / 100) * canvas.width, (this.y_axis / 100) * canvas.height);
            ctx.fillText(`Validade: ${this.valid}`, (this.x_axis / 100) * canvas.width, (this.y_axis / 100) * canvas.height + this.font_size);
        },

        formatDate(date) {
            if (!date) return '';
            
            const [year, month, day] = date.split('-');
            return `${day}/${month}/${year}`;
        },

        async createVoucher() {
            if (this.model.required_name === true && !this.text) return emitToastr('error', 'O texto do Voucher é obrigatório.')
            const base64 = canvas.toDataURL("image/png");
            const resp = await Api.createVoucher({ text: this.text, expiration_date: this.date, model: this.model._id, image: base64 });
            if (resp && resp.image) {
                window.open(resp.image, '_blank').focus();
            }
        }
    },
};
</script>
  
<style scoped>
.input {
    margin: 15px
}

.voucher-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

#voucher-image {
    display: none;
}

#myCanvas {
    height: auto;
    max-height: 50vh;
    max-width: 100%;
}
</style>
  