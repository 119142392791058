<template>
    <div class="models_container">
        <h1 style="margin-bottom: 20px;">Criar Modelo</h1>

        <div class="new_model">
            <v-card>
                <v-card-title>Criar novo modelo</v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="new_model.title" label="Título do modelo*" required outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                            <v-text-field v-model="new_model.description" label="Descrição do modelo" outlined hide-details="auto"></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-file-input accept="image/*" @change="uploadImage" label="Imagem de base" outlined dense :messages="fileInputMessage" :loading="disableSaveButton"></v-file-input>
                        </v-col>

                        <v-col cols="12" md="6">
                            <v-checkbox v-model="new_model.required_name" label="Obrigar cadastro do nome?"></v-checkbox>
                        </v-col>

                        <v-col cols="12">
                            <h4>Preview</h4>
                            <v-img contain :src="new_model.image" style="height: 400px; width: 800px;"></v-img>
                        </v-col>

                    </v-row>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed large color="primary" @click.native="create" :disabled="disableSaveButton">
                        Cadastrar modelo
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "CreateModelView",

    data() {
        return {
            new_model: { required_name: true },
            disableSaveButton: false,
            fileInputMessage: ''
        };
    },

    components: {},

    methods: {
        async create() {
            if (!this.new_model.title) return emitToastr('error', 'O título do modelo é obrigatório.');
            if (!this.new_model.image) return emitToastr('error', 'A imagem base do modelo é obrigatória.');

            const resp = await Api.createModel(this.new_model);
            if (resp && resp.model) {
                this.new_model = { required_name: true };
                return emitToastr('success', 'Modelo cadastrado com sucesso!');
            }
        },

        async uploadImage(data) {
            if (data) {
                this.$root.$refs.global.setLoading(true);
                this.disableSaveButton = true;
                this.fileInputMessage = "Comprimindo e salvando a imagem...";
                const resp = await Api.uploadImage(data);

                this.$root.$refs.global.setLoading(false);
                this.disableSaveButton = false;

                if (resp && resp.file) {
                    this.new_model.image = resp.file;
                }
                else {
                    //todo tratar
                }
            }
        }
    },
};
</script>
  
<style scoped></style>
  