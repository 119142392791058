<template>
    <div class="models_container">
        <h1 style="margin-bottom: 20px;">Gerenciar modelos</h1>


        <div class="models">
            <v-row>
                <v-col cols="12" md="4" sm="6" v-for="model in models" :key="model._id">
                    <v-card>
                        <v-card-title>{{ model.title }}</v-card-title>
                        <v-card-subtitle>{{ model.description }}</v-card-subtitle>

                        <v-card-text class="model-preview-container">
                            <v-img contain class="model-preview" :src="model.image"></v-img>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed block large color="error" @click.native="deleteModel(model._id)">
                                APAGAR MODELO
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "ViewModelsView",

    data() {
        return {
            models: []
        };
    },

    async mounted() {
        this.getModels();
    },

    methods: {
        async getModels() {
            const resp = await Api.getModels();
            if (resp && resp.models) this.models = resp.models;
        },

        async deleteModel(model_id) {
            if (confirm("Tem certeza que deseja apagar esse modelo? Essa operação é irreversível.") == true) {
                const resp = await Api.deleteModel(model_id);
                if (resp && resp.error === false) {
                    this.getModels();
                    return emitToastr('success', 'Modelo apagado com sucesso.');
                }
            }
        }
    },
};
</script>
  
<style scoped>
.model-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-preview {
    max-width: 200px;
    max-height: 200px;
}
</style>
  