<template>
    <div class="users_container">
        <h1 style="margin-bottom: 20px;">Usuários</h1>

        <div class="new-user-container">
            <v-card class="new-user-card">
                <v-card-title>Criar um novo usuário</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="new_user.name" label="Nome" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="new_user.email" label="Email" outlined hide-details="auto"></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field v-model="new_user.password" type="password" label="Senha" outlined hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed large color="primary" @click.native="createUser">
                        Cadastrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>


        <div class="users">
            <h2 style="margin-bottom: 20px;">Usuários cadastrados</h2>
            <v-row>
                <v-col cols="12" lg="4" md="4" sm="6" v-for="user in users" :key="user._id">
                    <v-card>
                        <v-card-title>{{ user.name }}</v-card-title>
                        <v-card-subtitle>{{ user.email }}</v-card-subtitle>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn depressed block large color="error" @click.native="deleteUser(user)">
                                Apagar usuário
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
  
<script>
import Api from "@/lib/Api";
import { emitToastr } from "@/lib/Utils";

export default {
    name: "UsersView",

    data() {
        return {
            users: [],
            new_user: {}
        };
    },

    async mounted() {
        this.getUsers();
    },

    methods: {
        async getUsers() {
            const resp = await Api.getUsers();
            if (resp && resp.users) this.users = resp.users;
        },

        async createUser() {
            if (!this.new_user.name) return emitToastr('error', 'Nome do usuário é obrigatório.')
            if (!this.new_user.email) return emitToastr('error', 'Email do usuário é obrigatório.')
            if (!this.new_user.password) return emitToastr('error', 'Senha do usuário é obrigatória.')
            this.new_user.admin = false;

            const resp = await Api.createUser(this.new_user);
            if (resp && resp.new_user) {
                this.getUsers()
                this.new_user = {}
                return emitToastr('success', 'Usuário criado com sucesso!');

            }
            else {
                console.log(resp)
            }
        },

        async deleteUser(user) {
            if (confirm("Tem certeza que deseja apagar esse usuário? Essa operação é irreversível.") == true) {
                const resp = await Api.deleteUser(user);
                if (resp && resp.error === false) {
                    this.getUsers();
                    return emitToastr('success', 'Usuário apagado com sucesso.');
                }
            }
        }
    },
};
</script>
  
<style scoped>
.model-preview-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.model-preview {
    max-width: 200px;
    max-height: 200px;
}

.new-user-container {
    margin-bottom: 30px;
}

.new-user-card {
    max-width: 700px;
}
</style>
  